var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-md-12 col-lg-12" },
      [
        _c("c-tab", {
          attrs: {
            dense: true,
            tabItems: _vm.tabItems,
            inlineLabel: true,
            height: _vm.tabHeight,
          },
          on: { tabClick: _vm.tabClick },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (tab) {
                return [
                  _c(tab.component, {
                    tag: "component",
                    attrs: {
                      tabParam: _vm.tabParam,
                      attachInfo: _vm.attachInfo,
                      useDate: _vm.useDate,
                    },
                    on: {
                      "update:tabParam": function ($event) {
                        _vm.tabParam = $event
                      },
                      "update:tab-param": function ($event) {
                        _vm.tabParam = $event
                      },
                      "update:attachInfo": function ($event) {
                        _vm.attachInfo = $event
                      },
                      "update:attach-info": function ($event) {
                        _vm.attachInfo = $event
                      },
                      closePopup: _vm.closePopup,
                      updateMode: _vm.updateMode,
                      getDetail: _vm.getDetail,
                    },
                  }),
                ]
              },
            },
          ]),
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }