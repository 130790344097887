<template>
  <div class="row">
    <div class="col-md-12 col-lg-12">
      <c-tab
        :dense="true"
        :tabItems="tabItems"
        :inlineLabel="true"
        :height="tabHeight"
        v-model="tab"
        @tabClick="tabClick"
      >
        <template v-slot:default="tab">
          <component
            :is="tab.component"
            :tabParam.sync="tabParam"
            :attachInfo.sync="attachInfo"
            :useDate="useDate"
            @closePopup="closePopup"
            @updateMode="updateMode"
            @getDetail="getDetail"
          />
        </template>
      </c-tab>
    </div>
  </div>
</template>
<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
export default {
  name: 'hazard-equipment-class-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        saiContructFacilityId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      tabItems: [
        { key: uid(),name: 'hazardEquipmentInfo', icon: 'info', label: '기계/장비 정보', component: () => import(`${'./hazardEquipmentInfo.vue'}`) },
      ],
      addTabItems: [
        { key: uid(),name: 'hazardEquipmentHistory', icon: 'construction', label: '자체점검이력 관리', component: () => import(`${'./hazardEquipmentHistory.vue'}`), },
      ],
      tabDisabled: false,
      tab: 'hazardEquipmentInfo',
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'MACHINERY_INFO',
        taskKey: '',
      },
      tabParam: {
        saiContructFacilityId: '',
        plantCd: '',
        plantName: '',
        processCd: '',
        processName: '',
        vendorCd: '',
        vendorName: '',
        facilityTypeId: '',
        facilityTypeCd: '',
        facilityTypeName: '',
        facilityName: '',
        deviceNo: '',
        installLocation: '',
        carryinDate: '',
        useStartYmd: '',
        useEndYmd: '',
        safetyDate: '',
        safetyResultFlag: 'Y',
        checkAction: '',
        specifications: '',
        operationAtor: '',
        checkValidPeriod: '일 남음',
        managerNo: '',
        managerUserId: '',
        managerUserName: '',
        regUserId: '',
        chgUserId: '',

        machinerySafetyMeasureModelList: [],
        deleteMachinerySafetyMeasureModelList: [],

        machineryHistoryModelList: [],
        deleteMachineryHistoryModelList: [],
      },
      useDate: [],
      count: 0,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 50);
    },
  },
  watch: {
  },
  methods: {
    init() {
      if (this.popupParam.saiContructFacilityId) {
        this.tabItems = this.tabItems.concat(this.addTabItems);
      }
      this.getUrl = selectConfig.sai.machinery.get.url;
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.saiContructFacilityId) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.saiContructFacilityId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.tabParam = this.$_.clone(_result.data);
          if (_result.data.useStartYmd && _result.data.useEndYmd) {
            this.useDate = [this.tabParam.useStartYmd, this.tabParam.useEndYmd]
          }
          this.$set(this.attachInfo, 'taskKey', this.popupParam.saiContructFacilityId)
        },);
      }
    },
    updateMode(_saiContructFacilityId) {
      this.popupParam.saiContructFacilityId = _saiContructFacilityId;

      this.getDetail();

      this.tabItems = this.tabItems.concat(this.addTabItems);
    },
    closePopup() {
      this.$emit('closePopup')
    },
    tabClick() {
      this.count++;
    }
  }
};
</script>